import { forwardRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { toast } from 'react-toastify';

import Show from 'components/Show';
import Loading from 'components/Loading';

const AsyncPage = forwardRef(({ query, children, className, errorFn }, ref) => {
  useEffect(() => {
    if (query.isError) {
      if (query.error?.toast) {
        toast.error(
          <>
            <div className="text-lg">{query.error.toast_message}</div>
            <Show show={query.error.toast_instruction}>
              <div className="text-sm">{query.error.toast_instruction}</div>
            </Show>
          </>,
        );
      }
      if (errorFn) {
        errorFn();
      }
    }
  }, [query]);

  return (
    <div className={twMerge('flex flex-auto flex-col', className)} ref={ref}>
      {/* Loading Page */}
      <Show show={query.isLoading}>
        <Loading />
      </Show>

      {/* Error Page */}
      <Show
        show={query.isError && !errorFn}
        as="div"
        className="flex h-full flex-auto flex-col items-center justify-center"
      >
        <div className="text-3xl font-bold text-gray-900">{query.error?.error_page_title}</div>
        <Show show={query.error?.uuid} as="div" className="mt-4 text-lg text-red-600">
          <div className="text-center font-bold">Error ID</div>
          <div>{query.error?.uuid}</div>
        </Show>
        <div className="mb-8 mt-4 text-2xl text-gray-500">{query.error?.error_page_instruction}</div>
        <Link to={-1} className="rounded-lg bg-slate-500 px-4 py-2 text-lg text-white hover:bg-slate-700">
          Go Back
        </Link>
      </Show>

      {/* Succeeded Page */}
      <Show show={query.isSuccess}>{children}</Show>
    </div>
  );
});

export default AsyncPage;
