import { ContentContainer, ContentTitle } from 'components/Content';

export default function Contact() {
  return (
    <ContentContainer>
      <ContentTitle>Contact</ContentTitle>
      <div className="mb-2 border-b border-slate-800 pb-2 text-3xl font-semibold">Managing Director</div>
      <div className="text-2xl">Professor. Jeong Won Kang</div>
      <div className="mt-1 text-lg">Molecular Design Lab</div>
      <div className="text-lg">Korea University Department of Chemical and Biological Engineering</div>

      <div className="mb-2 mt-6 border-b border-slate-800 pb-2 text-2xl font-semibold">Phone</div>
      <div className="text-xl">+82-2-3290-3305</div>
      <div className="mb-2 mt-6 border-b border-slate-800 pb-2 text-2xl font-semibold">E-mail</div>
      <div className="text-xl">jwkang@korea.ac.kr</div>
      <div className="mb-4 mt-6 border-b border-slate-800 pb-2 text-2xl font-semibold">Location</div>
      <div className="aspect-square w-full max-w-xl" aria-label="iframe container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3161.8325113455126!2d127.02369497664024!3d37.58256147203467!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357cbd4279f29c71%3A0xe4e68bbe651c1494!2z6rOg66Ck64yA7ZWZ6rWQIOyLoOqzte2Vmeq0gA!5e0!3m2!1sko!2skr!4v1737456627288!5m2!1sko!2skr"
          width="100%"
          height="100%"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div>
      <div className="my-4 text-xl">
        <div>73-15, Anam-ro, Seongbuk-gu, Seoul, Republic of Korea</div>
        <div>Korea Universtiy New Engineering Building 717</div>
      </div>
      <div className="mt-2 text-xl">
        <div>서울 성북구 안암로 73-15</div>
        <div>고려대학교 신공학관 717호</div>
      </div>
      <div className="mb-4 mt-16 border-b border-slate-800 pb-2 text-3xl font-semibold">
        For technical issues,
        <br />
        please contact following address
      </div>
      <div className="text-2xl">Sun Yoo Hwang</div>
      <div className="mt-1 text-lg">Molecular Design Lab</div>
      <div className="text-lg">Korea University Department of Chemical and Biological Engineering</div>
      <div className="mb-2 mt-6 border-b border-slate-800 pb-2 text-2xl font-semibold">E-mail</div>
      <div className="text-xl">neros12@korea.ac.kr</div>
    </ContentContainer>
  );
}
