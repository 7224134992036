import { ContentContainer, ContentTitle } from 'components/Content';

import { citingJournals } from 'data/citaions';

export default function Citation() {
  return (
    <ContentContainer>
      <ContentTitle>Citation</ContentTitle>
      <div className="text-2xl font-semibold">To reference KDB, please cite the following paper.</div>
      <a
        className="mb-12 mt-4 block border-4 bg-gray-50 p-4 text-xl font-light hover:text-blue-600"
        href="https://link.springer.com/article/10.1023/A:1010726915591"
        target="_blank"
      >
        Kang, J. W., et al. "Development and current status of the Korea Thermophysical Properties Databank
        &#40;KDB&#41;." International journal of thermophysics 22 &#40;2001&#41;: 487-494.
      </a>

      <div className="mb-4 text-2xl font-bold">Journal Articles Citing KDB</div>
      <div className="space-y-8 text-lg font-light leading-6">
        {citingJournals.map((journal, idx) => (
          <a className="block" target="_blank" href={journal.href} key={idx}>
            {journal.MLA}
          </a>
        ))}
      </div>
    </ContentContainer>
  );
}
