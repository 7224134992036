import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { BiChevronsLeft } from 'react-icons/bi';

import { ContentContainer, ContentTitle } from 'components/Content';
import { useCustomQuery } from 'hooks/query-hooks';
import { capitalizeFirstLetter } from 'utils/data-treatment';
import Base64Image from 'components/base/Base64Image';
import request from 'utils/http-request';
import AsyncPage from 'components/AsyncPage';

function searchBinaryVLEID(VLESetID) {
  const query = useCustomQuery({
    queryKey: ['search_binary_VLE_ID', VLESetID],
    queryFn: async () => {
      return await request({
        url: `/binary-vle/search/${VLESetID}`,
        method: 'GET',
      });
    },
  });

  return query;
}

export default function SearchBinaryVLEData() {
  const params = useParams();
  const searchBinaryVLEIDResult = searchBinaryVLEID(params.id);

  const [title, setTitle] = useState('');
  const [compound1, setCompound1] = useState('');
  const [compound2, setCompound2] = useState('');
  const [compound1Image, setCompound1Image] = useState('');
  const [compound2Image, setCompound2Image] = useState('');
  const [VLEData, setVLEData] = useState([]);

  useEffect(() => {
    if (searchBinaryVLEIDResult.isSuccess) {
      const queryData = searchBinaryVLEIDResult.data.data;

      setTitle(queryData.VLE_set.title);
      setCompound1(capitalizeFirstLetter(queryData.compounds.compound1.name));
      setCompound2(capitalizeFirstLetter(queryData.compounds.compound2.name));
      setCompound1Image(queryData.compounds.compound1.image);
      setCompound2Image(queryData.compounds.compound2.image);
      setVLEData(queryData.VLE_data);
    }
  }, [searchBinaryVLEIDResult.data]);

  return (
    <AsyncPage query={searchBinaryVLEIDResult}>
      <ContentContainer>
        <ContentTitle>{title}</ContentTitle>
        <div className="mb-8 mt-4 border-b-2 border-slate-700 p-2 text-3xl font-bold">Component Summary</div>
        <div className="flex gap-x-12">
          <div className="w-64 text-center">
            <div className="text-2xl font-bold">Component1</div>
            <Base64Image className="my-2 border border-slate-400" src={compound1Image} />
            <div className="text-xl font-semibold">{compound1}</div>
          </div>
          <div className="w-64 text-center">
            <div className="text-2xl font-bold">Component2</div>
            <Base64Image className="my-2 border border-slate-400" src={compound2Image} />
            <div className="text-xl font-semibold">{compound2}</div>
          </div>
        </div>

        <div className="mb-2 mt-24 border-b-2 border-slate-700 p-2 text-3xl font-bold">Data</div>
        <div className="mb-2 grid grid-cols-4 gap-x-2 text-xl font-bold">
          <div>
            X<sub>1</sub>
          </div>
          <div>
            Y<sub>1</sub>
          </div>
          <div>Temperautere &#40;K&#41;</div>
          <div>Pressure &#40;kPa&#41;</div>
        </div>
        {VLEData.map(data => (
          <div key={data.data_id} className="grid grid-cols-4 gap-x-2 text-xl">
            <div>{data.X1 !== '200000' && Number(data.x1_value).toPrecision(3)}</div>
            <div>{data.Y1 !== '200000' && Number(data.y1_value).toPrecision(3)}</div>
            <div>{data.T !== '200000' && Number(data.temperature_value).toPrecision(5)}</div>
            <div>{data.P !== '200000' && Number(data.pressure_value).toPrecision(5)}</div>
          </div>
        ))}

        <Link className="mb-12 mt-20 flex items-center text-xl text-blue-800 hover:text-blue-500" to={-1}>
          <BiChevronsLeft className="mr-2 mt-0.5" /> Go Back
        </Link>
      </ContentContainer>
    </AsyncPage>
  );
}
