import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useCustomQuery } from 'hooks/query-hooks';
import { ContentContainer, ContentTitle } from 'components/Content';
import { capitalizeFirstLetter } from 'utils/data-treatment';
import Show from 'components/Show';
import Pagination from 'components/Paginantion';
import request from 'utils/http-request';
import AsyncPage from 'components/AsyncPage';

function searchBinaryVLE(compound1, compound2, current_page) {
  const query = useCustomQuery({
    queryKey: ['search_binary_VLE', compound1, compound2, current_page],
    queryFn: async () => {
      return await request({
        url: '/binary-vle/search',
        method: 'GET',
        params: { compound1: compound1, compound2: compound2, page: current_page },
      });
    },
  });

  return query;
}

function SearchVLEResultContainer({ queryData, idToName }) {
  useEffect(() => {
    console.log(queryData);
  }, []);
  return (
    <Link
      className="mb-8 block border-2 p-4 hover:border-slate-700"
      to={`/search/binary-vle/${queryData.vle_set_id}`}
    >
      <h3 className="text-2xl font-bold">{queryData.memo}</h3>
      <div className="mt-2 flex flex-wrap gap-x-8 text-lg">
        <div className="whitespace-nowrap">
          <span className="font-semibold">Component 1</span> : {idToName?.[queryData.compound_id1]}
        </div>
        <div className="whitespace-nowrap">
          <span className="font-semibold">Component 2</span> : {idToName?.[queryData.compound_id2]}
        </div>
      </div>
      <div className="mt-1 text-xl">
        <div className="mr-4 font-semibold">Pressure Range</div>
        <Show show={queryData.PMin === 200000 && queryData.PMax === 200000}>No Data</Show>
        <Show show={queryData.PMin !== 200000 && queryData.PMin && queryData.PMin !== ''}>
          {queryData.PMin.toPrecision(5)}
        </Show>
        <Show show={queryData.PMax !== 200000}>&nbsp;&nbsp;~&nbsp;&nbsp;{queryData.PMax.toPrecision(5)}</Show>
        <Show show={queryData.PMin !== 200000 && queryData.PMax !== 200000}>&nbsp;&nbsp;kPa</Show>
      </div>
      <div className="text-xl">
        <div className="mr-4 font-semibold">Temperature Range</div>
        <Show show={queryData.TMin === 200000 && queryData.TMax === 200000}>No Data</Show>
        <Show show={queryData.TMin !== 200000}>{queryData.TMin.toPrecision(5)}</Show>
        <Show show={queryData.TMax !== 200000}>&nbsp;&nbsp;~&nbsp;&nbsp;{queryData.TMax.toPrecision(5)}</Show>
        <Show show={queryData.TMin !== 200000 && queryData.TMax !== 200000}>&nbsp;&nbsp;K</Show>
      </div>
      <div className="text-xl font-semibold">Concentration Range</div>
      <div className="text-lg">
        X<sub>1</sub> : &nbsp;
        <Show show={queryData.XMin === 200000 && queryData.XMax === 200000}>No Data</Show>
        <Show show={queryData.XMin !== 200000}>{queryData.XMin.toPrecision(3)}</Show>
        <Show show={queryData.XMax !== 200000}>&nbsp;&nbsp;~&nbsp;&nbsp;{queryData.XMax.toPrecision(3)}</Show>
      </div>
      <div className="text-lg">
        Y<sub>1</sub> : &nbsp;
        <Show show={queryData.YMin === 200000 && queryData.YMax === 200000}>No Data</Show>
        <Show show={queryData.YMin !== 200000}>{queryData.YMin.toPrecision(3)}</Show>
        <Show show={queryData.YMax !== 200000}>&nbsp;&nbsp;~&nbsp;&nbsp;{queryData.YMax.toPrecision(3)}</Show>
      </div>
    </Link>
  );
}

export default function SearchBinaryVLEID() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const compound1 = searchParams.get('cmp1');
  const compound2 = searchParams.get('cmp2');
  const currentPage = searchParams.get('page');
  const [idToName, setIdToName] = useState();

  const searchResults = searchBinaryVLE(compound1, compound2, currentPage);

  useEffect(() => {
    setIdToName({
      [searchResults.data?.data.compounds.compound1.compound_id]: capitalizeFirstLetter(
        searchResults.data?.data.compounds.compound1.name,
      ),
      [searchResults.data?.data.compounds.compound2.compound_id]: capitalizeFirstLetter(
        searchResults.data?.data.compounds.compound2.name,
      ),
    });

    if (searchResults.data?.data.num_data === 0) {
      toast.error('No data has been found');
      navigate('/search/binary-vle');
    }
  }, [searchResults.data?.data]);

  return (
    <AsyncPage query={searchResults}>
      <ContentContainer>
        <ContentTitle>
          Found {searchResults.data?.data.num_data} Results
          <div className="pt-1 text-xl font-normal">
            for <span className="font-bold">{compound1}</span> and{' '}
            <span className="font-bold">{compound2}</span>
          </div>
        </ContentTitle>
        {searchResults.data?.data.data.map(item => (
          <SearchVLEResultContainer queryData={item} key={item.vle_set_id} idToName={idToName} />
        ))}
        <Pagination numData={searchResults.data?.data.num_data} />
      </ContentContainer>
    </AsyncPage>
  );
}
