import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import Show from 'components/Show';

export function LogoKDB({ className, subtitle = true }) {
  return (
    <Link to="/" className={twMerge('block w-fit text-white', className)}>
      <div className="text-6xl font-bold">KDB</div>
      <Show show={subtitle}>
        <div className="mt-0.5 font-light">Korea Thermophysical Properties Data Bank</div>
      </Show>
    </Link>
  );
}

export function LogoMDL({ className, subtitle = true }) {
  return (
    <a href="http://www.cbemdl.com" target="_blank" className={twMerge('flex h-16 space-x-2', className)}>
      <img className="h-full" src="/images/mdl_logo.png" />
      <Show as="div" show={subtitle} className="flex w-fit flex-col justify-center whitespace-nowrap">
        <div className="text-xl font-semibold">
          <span>Molecular</span>
          <span className="mx-1">Design</span>
          <span>Labatory</span>
        </div>
        <div className="text-lg text-slate-800">고려대학교 분자설계연구실</div>
      </Show>
    </a>
  );
}

export function LogoKoreaUniv({ className, subtitle = true }) {
  return (
    <a href="https://cbe.korea.ac.kr" target="_blank" className={twMerge('flex h-[4.2rem] space-x-2', className)}>
      <img className="h-full" src="/images/korea_university_logo.png" />
      <div className="flex w-fit flex-col justify-center whitespace-nowrap leading-3 text-red-800">
        <div className="font-bold">고려대학교 화공생명공학과</div>
        <div className="mt-1 text-sm">Korea University Department of</div>
        <div className="-mt-1.5 text-sm">Chemical and Biological Engineering</div>
      </div>
    </a>
  );
}

export function LogoNCSRD({ className }) {
  return (
    <a className={className} href="https://www.srd.re.kr/css/index.do" target="_blank">
      <img className="mx-auto h-16 w-80" src="/images/ncsrd_logo.png" />
      <div className="text-center font-semibold text-gray-600">National Center for Standard Reference Data</div>
    </a>
  );
}
