import { Fragment } from 'react';
import { twMerge } from 'tailwind-merge';

const groups = [
  { index: 1, group: '–CH3', remark: '' },
  { index: 2, group: '=CH2', remark: '' },
  { index: 3, group: '≡CH', remark: '' },
  { index: 4, group: '–CH2–', remark: '' },
  { index: 5, group: '>CH–', remark: '' },
  { index: 6, group: '>C<', remark: '' },
  { index: 7, group: '=CH–', remark: '' },
  { index: 8, group: '=C<', remark: '' },
  { index: 9, group: '=C=', remark: '' },
  { index: 10, group: '≡C–', remark: '' },
  { index: 11, group: '–CH2–', remark: 'RING' },
  { index: 12, group: '>CH–', remark: 'RING' },
  { index: 13, group: '>C<', remark: 'RING' },
  { index: 14, group: '=CH–', remark: 'RING' },
  { index: 15, group: '=C<', remark: 'RING' },
  { index: 16, group: '=C=', remark: 'RING' },
  { index: 17, group: '≡C–', remark: 'RING' },
  { index: 18, group: '–CH2–', remark: 'AROMATIC' },
  { index: 19, group: '>CH–', remark: 'AROMATIC' },
  { index: 20, group: '=CH–', remark: 'AROMATIC' },
  { index: 21, group: '=C<', remark: 'AROMATIC' },
  { index: 22, group: '–F', remark: 'HALOGEN' },
  { index: 23, group: '–Cl', remark: 'HALOGEN' },
  { index: 24, group: '–Br', remark: 'HALOGEN' },
  { index: 25, group: '–I', remark: 'HALOGEN' },
  { index: 26, group: '–OH', remark: '' },
  { index: 27, group: '–O–', remark: '' },
  { index: 28, group: '–O–', remark: 'RING' },
  { index: 29, group: '–O–', remark: 'AROMATIC' },
  { index: 30, group: '=O', remark: '' },
  { index: 31, group: '–NH2', remark: '' },
  { index: 32, group: '>NH', remark: '' },
  { index: 33, group: '>NH', remark: 'RING' },
  { index: 34, group: '>NH', remark: 'AROMATIC' },
  { index: 35, group: '>N–', remark: '' },
  { index: 36, group: '>N–', remark: 'RING' },
  { index: 37, group: '>N–', remark: 'AROMATIC' },
  { index: 38, group: '–N=', remark: '' },
  { index: 39, group: '–N=', remark: 'RING' },
  { index: 40, group: '–N=', remark: 'AROMATIC' },
  { index: 41, group: '≡N', remark: '' },
  { index: 42, group: '–SH', remark: '' },
  { index: 43, group: '–S–', remark: '' },
  { index: 44, group: '–S–', remark: 'RING' },
  { index: 45, group: '–S–', remark: 'AROMATIC' },
  { index: 46, group: '=S', remark: '' },
  { index: 47, group: '–O[–]', remark: '' },
  { index: 48, group: '=N[+]<', remark: '' },
];

export default function GCgcnGroups({ className }) {
  return (
    <>
      {/* WIDE SCREEN */}
      <div className={twMerge('flex max-w-2xl', className)}>
        <div className="mr-12 grid grid-cols-4">
          <div className="font-bold">Index</div>
          <div className="col-span-2 font-bold">Group</div>
          <div className="font-bold">Remark</div>
          {groups.map(group => {
            if (group.index < 24)
              return (
                <Fragment key={`index_${group.index}`}>
                  <div className="p-1">{group.index}</div>
                  <div className="col-span-2 p-1">{group.group}</div>
                  <div className="p-1">{group.remark}</div>
                </Fragment>
              );
          })}
        </div>
        <div className="grid grid-cols-4">
          <div className="font-bold">Index</div>
          <div className="col-span-2 font-bold">Group</div>
          <div className="font-bold">Remark</div>
          {groups.map(group => {
            if (group.index > 23)
              return (
                <Fragment key={`index_${group.index}`}>
                  <div className="p-1">{group.index}</div>
                  <div className="col-span-2 p-1">{group.group}</div>
                  <div className="p-1">{group.remark}</div>
                </Fragment>
              );
          })}
        </div>
      </div>

      {/* NARROW SCREEN */}
    </>
  );
}
