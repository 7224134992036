import { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useLocationContext } from 'contexts/LocationContext';

export function OnlyKorea({ children = <Outlet /> }) {
  const { isKorea } = useLocationContext();

  useEffect(() => {
    if (!isKorea) {
      toast.error('This service can be only accessed from Korea');
    }
  }, [isKorea]);

  if (isKorea) return children;
  else return <Navigate to="/" replace />;
}
