const httpErrorList = [
  {
    code: 'ERROR_404_endpoint_not_found',
    toast_message: '요청하신 페이지를 찾을 수 없습니다.',
    instruction: undefined,
    toast: true,
  },
  {
    code: 'ERROR_415_unsupported_molecule',
    toast_message: 'Unsupported Molecule',
    instruction: undefined,
    toast: true,
  },
  {
    code: 'ERROR_415_unsupported_SMILES_format',
    toast_message: 'Unsupported SMILES Format',
    instruction: undefined,
    toast: true,
  },
  {
    code: 'ERROR_415_too_large_molecule',
    toast: true,
    toast_message: 'Too Large Molecule',
    toast_instruction: 'Please enter a molecule containing 3 to 25 heavy atoms.',
  },
  {
    code: 'ERROR_415_INAPPROPRIATE_COMPOUND_FORMAT',
    toast: true,
    toast_message: 'Inapporiate compound',
    toast_instruction: 'Please check your compound',
    error_page_title: undefined,
    error_page_instruction: undefined,
  },
  {
    code: 'ERROR_500_UNDEFINED_ERROR',
    toast: false,
    toast_title: undefined,
    toast_message: undefined,
    error_page_title: 'Unexpected Error Occurred',
    error_page_instruction: 'Please contact the staff for support.',
  },
];

export { httpErrorList };
