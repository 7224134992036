import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useCustomQuery } from 'hooks/query-hooks';
import { ContentContainer } from 'components/Content';
import { capitalizeFirstLetter, roundToSignificantFigures } from 'utils/data-treatment';
import { toStringCASRN } from 'utils/casrn';
import Base64Image from 'components/base/Base64Image';
import Show from 'components/Show';
import request from 'utils/http-request';
import AsyncPage from 'components/AsyncPage';
import NoWarranty from 'components/NoWarranty';

function searchComponent(param) {
  const query = useCustomQuery({
    queryKey: ['search_pure_compound_id', param],
    queryFn: async () => {
      return await request({
        url: `/pure/search/${param}`,
        method: 'GET',
      });
    },
  });

  return query;
}

function PropertyContainer({ data, name, unit }) {
  return (
    <div aria-label="property" className="mb-4">
      <h3 className="property-h3">{name}</h3>
      <Show show={data?.value}>
        <div className="text-lg">
          <Show show={data?.data_measure === 'Estimated with GC-GCN'}>
            {roundToSignificantFigures(data?.value, 5)}
          </Show>
          <Show show={data?.data_measure !== 'Estimated with GC-GCN'}>{data?.value}</Show>
          &nbsp;
          <Show show={data?.unc !== '200000'}>
            ±&nbsp;
            <Show show={data?.data_measure === 'Estimated with GC-GCN'}>
              {roundToSignificantFigures(data?.unc, 5)}
            </Show>
            <Show show={data?.data_measure !== 'Estimated with GC-GCN'}>{data?.unc}</Show>
          </Show>
          &nbsp;{unit}
        </div>
        <div className="mt-1 text-lg font-light italic text-gray-700">- {data?.data_measure}</div>
        <div className="flex items-center text-lg text-green-600">
          <span className="mr-2">-</span> grade : {data?.grade}
        </div>
      </Show>
      <Show show={!data?.value} as="div" className="text-lg font-semibold text-gray-400">
        No Data
      </Show>
    </div>
  );
}

export default function SearchPureData() {
  const params = useParams();
  const navigate = useNavigate();
  const searchResults = searchComponent(params.id);

  const [compoundImage, setCompoundImage] = useState('');
  const [compoundName, setCompoundName] = useState('');
  const [CASRN, setCASRN] = useState('');
  const [molecularFormula, setMolecularFormula] = useState('');
  const [molecularWeight, setMolecularWeight] = useState('');
  const [SMILES, setSMILES] = useState('');
  const [InChI, setInChI] = useState('');
  const [InChIKey, setInChIKey] = useState('');
  const [Tb, setTb] = useState('');
  const [Tm, setTm] = useState('');
  const [TPT, setTPT] = useState('');
  const [TPP, setTPP] = useState('');
  const [Tc, setTc] = useState('');
  const [Pc, setPc] = useState('');
  const [Vc, setVc] = useState('');
  const [Zc, setZc] = useState('');
  const [Tf, setTf] = useState('');
  const [AIT, setAIT] = useState('');
  const [Kow, setKow] = useState('');
  const [ACC, setACC] = useState('');
  const [SOLP, setSOLP] = useState('');
  const [VSTAR, setVSTAR] = useState('');
  const [LFL, setLFL] = useState('');
  const [UFL, setUFL] = useState('');
  const [Hform, setHform] = useState('');
  const [Gform, setGform] = useState('');
  const [Hfus, setHfus] = useState('');

  useEffect(() => {
    if (searchResults.isSuccess && Object.keys(searchResults.data.data).length > 0) {
      const queryData = searchResults.data.data;

      setCompoundImage(queryData.information.image);
      setCompoundName(capitalizeFirstLetter(queryData.information.name));
      setCASRN(toStringCASRN(queryData.information.CASRN));
      setMolecularFormula(queryData.information.formula);
      setMolecularWeight(queryData.information.molecular_weight);
      setSMILES(queryData.information.SMILES);
      setInChI(queryData.information.InChI);
      setInChIKey(queryData.information.InChIKey);
      setTb(queryData.Tb);
      setTm(queryData.Tm);
      setTc(queryData.Tc);
      setPc(queryData.Pc);
      setVc(queryData.Vc);
      setZc(queryData.Zc);
      setTPT(queryData.TPT);
      setTPP(queryData.TPP);
      setTf(queryData.Tf);
      setAIT(queryData.AIT);
      setKow(queryData.Kow);
      setACC(queryData.ACC);
      setSOLP(queryData.SOLP);
      setVSTAR(queryData.VSTAR);
      setLFL(queryData.LFL);
      setUFL(queryData.UFL);
      setHform(queryData.Hform);
      setGform(queryData.Gform);
      setHfus(queryData.Hfus);
    } else if (searchResults.isSuccess) {
      toast.error('Invalid Access');
      navigate('/');
    }
  }, [searchResults.data]);

  return (
    <AsyncPage query={searchResults}>
      <ContentContainer>
        {/* PC Size */}
        <div className="mb-12 hidden gap-x-8 md:flex">
          <Base64Image className="aspect-square h-64 border border-slate-500 p-2" src={compoundImage} />
          <div className="flex flex-col justify-between text-xl">
            <h3 className="text-5xl font-bold">{compoundName}</h3>
            <div>
              <div>
                <span className="font-bold">CASRN : </span>
                {CASRN}
              </div>
              <div>
                <span className="font-bold">Forumla : </span>
                {molecularFormula}
              </div>
              <div>
                <span className="font-bold">Molecular Weight : </span>
                {molecularWeight}
              </div>
              <div>
                <span className="font-bold">SMILES : </span>
                {SMILES}
              </div>
              <div>
                <span className="font-bold">InChI : </span>
                {InChI}
              </div>
              <div>
                <span className="font-bold">InChIKey : </span>
                {InChIKey}
              </div>
            </div>
          </div>
        </div>

        {/* Mobile Size */}
        <div className="mx-auto w-full max-w-[30rem] border border-slate-500 p-8 md:hidden">
          <Base64Image
            className="mx-auto aspect-square w-full max-w-64 border border-slate-500 p-2"
            src={compoundImage}
          />
          <h3 className="mb-8 mt-4 text-center text-5xl font-bold">{compoundName}</h3>
          <div className="text-xl font-semibold">CASRN</div>
          <div className="text-lg">{CASRN}</div>
          <div className="mt-1 text-xl font-semibold">Formula</div>
          <div className="text-lg">{molecularFormula}</div>
          <div className="mt-1 text-xl font-semibold">Molecular Weight</div>
          <div className="text-lg">{molecularWeight}</div>
          <div className="mt-1 text-xl font-semibold">SMILES</div>
          <div className="text-lg">{SMILES}</div>
          <div className="mt-1 text-xl font-semibold">InChI</div>
          <div className="text-lg">{InChI}</div>
          <div className="mt-1 text-xl font-semibold">InChIKey</div>
          <div className="text-lg">{InChIKey}</div>
        </div>

        <h2 className="property-h2">Phase Transition Properties</h2>
        <PropertyContainer name="Boiling Temperature" data={Tb} unit="K" />
        <PropertyContainer name="Melting Temperature" data={Tm} unit="K" />
        <PropertyContainer name="Triple Point Temperature" data={TPT} unit="K" />
        <PropertyContainer name="Triple Point Pressure" data={TPP} unit="kPa" />
        <PropertyContainer name="Accentric Factor" data={ACC} unit="" />
        <PropertyContainer name="Octanol Water Coefficient" data={Kow} unit="" />

        <h2 className="property-h2">Phase Critical Properties</h2>
        <PropertyContainer name="Critical Temperature" data={Tc} unit="K" />
        <PropertyContainer name="Critical Pressure" data={Pc} unit="kPa" />
        <PropertyContainer name="Critical Volume" data={Vc} unit="L/mol" />
        <PropertyContainer name="Compressibility Factor" data={Zc} unit="" />

        <h2 className="property-h2">Liquid Properties</h2>
        <PropertyContainer name="Solubility Parameters" data={SOLP} unit="(Pa)^0.5" />
        <PropertyContainer name="COSTALD Characteristic Volume" data={VSTAR} unit="m^3/kg-mol" />

        <h2 className="property-h2">Enthalpy Property</h2>
        <PropertyContainer name="Heat of Fusion" data={Hfus} unit="kJ/kg-mol" />
        <PropertyContainer name="Heat of Formation (Ideal Gas)" data={Hform} unit="kJ/kg-mol" />
        <PropertyContainer name="Gibbs Enery of Formation (Ideal Gas)" data={Gform} unit="kJ/kg-mol" />

        <h2 className="property-h2">Safty Hazard Properties</h2>
        <PropertyContainer name="Low Flammability Limit" data={LFL} unit="% in air" />
        <PropertyContainer name="Upper Flammability Limit" data={UFL} unit="% in air" />
        <PropertyContainer name="Flash Point" data={Tf} unit="K" />
        <PropertyContainer name="Auto Ignition Temperature" data={AIT} unit="K" />

        <NoWarranty />
      </ContentContainer>
    </AsyncPage>
  );
}
