import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { VscChevronUp, VscChevronDown } from 'react-icons/vsc';
import { Field, Label, Radio, RadioGroup } from '@headlessui/react';
import { FcInfo } from 'react-icons/fc';

import Show from 'components/Show';
import { ContentContainer, ContentTitle } from 'components/Content';
import ComponentInput from 'components/ComponentInput';
import NoWarranty from 'components/NoWarranty';
import KetcherModal from 'components/KetcherModal';

const systemOptions = ['Isotherm', 'Isobaric'];
const activityCoefficientModels = ['NIST-UNIFAC', 'COSMO-SAC', 'COSMO-SAC-ML'];
const vaporPressureModels = ['Wagner25', 'GCGCN-VP'];

export default function CalculateBinaryVLE() {
  const ketcherRef = useRef();
  const navigate = useNavigate();
  const [showKetcher, setShowKetcher] = useState(false);
  const [selectedOption, setSelectedOption] = useState(systemOptions[1]);
  const [selectedActivityCoefficentModel, setSelectedActivityCoefficientModel] = useState(
    activityCoefficientModels[1],
  );
  const [selectedVaporPressureModel, setSelecetedVaporPressureModel] = useState(vaporPressureModels[0]);
  const [component1, setComponent1] = useState('');
  const [component2, setComponent2] = useState('');
  const [temperature, setTemperature] = useState('');
  const [isComponent1, setIsComponent1] = useState(true);
  const [showOptions, setShowOptions] = useState(true);

  const modalClick = () => {
    if (isComponent1) {
      ketcherRef.current.getSmiles().then(smiles => {
        setComponent1(smiles);
        ketcherRef.current.setMolecule('');
        setShowKetcher(false);
      });
    } else {
      ketcherRef.current.getSmiles().then(smiles => {
        setComponent2(smiles);
        ketcherRef.current.setMolecule('');
        setShowKetcher(false);
      });
    }
  };

  const onSubmit = e => {
    e.preventDefault();

    if (component1.length >= 1 && component2.length >= 1) {
      const component1Query = encodeURIComponent(component1);
      const component2Query = encodeURIComponent(component2);
      const temperatureQuery = encodeURIComponent(temperature);
      const optionQuery = selectedOption.toLowerCase();
      navigate(
        `result?cmp1=${component1Query}&cmp2=${component2Query}&tmp=${temperatureQuery}&option=${optionQuery}&ac=${selectedActivityCoefficentModel}&vp=${selectedVaporPressureModel}`,
      );
    }
  };

  return (
    <>
      <ContentContainer>
        <ContentTitle>Binary VLE Calculator</ContentTitle>
        <form className="border-2 border-slate-400 bg-gray-50 p-4 sm:p-8" onSubmit={onSubmit}>
          <div className="mb-2 text-xl">Component1</div>
          <div className="flex">
            <ComponentInput
              className="h-14 w-full text-xl placeholder:text-gray-400"
              value={component1}
              onChange={e => setComponent1(e.target.value)}
            />
          </div>
          <button
            className="mt-4 text-xl font-semibold hover:text-blue-600"
            type="button"
            onClick={() => {
              setIsComponent1(true);
              setShowKetcher(true);
            }}
          >
            - Draw Structure
          </button>
          <div className="mb-2 mt-6 text-xl">Component2</div>
          <div className="flex">
            <ComponentInput
              className="h-14 w-full text-xl placeholder:text-gray-400"
              value={component2}
              onChange={e => setComponent2(e.target.value)}
            />
          </div>
          <button
            className="mt-4 text-xl font-semibold hover:text-blue-600"
            type="button"
            onClick={() => {
              setIsComponent1(false);
              setShowKetcher(true);
            }}
          >
            - Draw Structure
          </button>
          <Show show={selectedOption === 'Isotherm'}>
            <div className="mb-2 mt-6 text-xl">Temperature</div>
            <div className="flex items-center gap-x-4">
              <ComponentInput
                className="mt-2 h-12 w-full max-w-32 text-xl"
                value={temperature}
                onChange={e => {
                  const onlyNumber = e.target.value.replace(/[^0-9.]/g, '');
                  setTemperature(onlyNumber);
                }}
              />
              <div className="text-2xl">K</div>
            </div>
          </Show>
          <button
            type="submit"
            className="mt-6 w-full rounded-xl bg-slate-400 p-2 text-xl font-semibold text-white hover:bg-slate-500"
          >
            Submit
          </button>
          <button
            type="button"
            className="mt-16 flex items-center text-xl font-semibold"
            onClick={() => setShowOptions(current => !current)}
          >
            <p className="mr-2">Calculation Options</p>
            {showOptions ? (
              <VscChevronUp className="mt-1 text-black" />
            ) : (
              <VscChevronDown className="mt-1 text-black" />
            )}
          </button>
          <Show
            as="div"
            show={showOptions}
            className="mt-4 grid grid-cols-1 gap-y-4 pt-2 md:grid-cols-2 md:gap-x-8"
          >
            <div>
              <h3 className="text-xl font-bold">System Options</h3>
              <RadioGroup
                value={selectedOption}
                onChange={setSelectedOption}
                aria-label="System Options"
                className="mt-4 bg-white px-4 py-2"
              >
                {systemOptions.map(systemOption => (
                  <Field key={systemOption} className="flex items-center gap-2 py-2">
                    <Label className="flex-auto text-lg">{systemOption}</Label>
                    <Radio
                      value={systemOption}
                      className="group flex size-5 cursor-pointer items-center justify-center rounded-full border border-black bg-white data-[checked]:bg-black"
                    >
                      <span className="invisible size-2 rounded-full bg-white group-data-[checked]:visible" />
                    </Radio>
                  </Field>
                ))}
              </RadioGroup>
            </div>
            <div>
              <h3 className="text-xl font-bold">Activity Coefficeint Models</h3>
              <RadioGroup
                value={selectedActivityCoefficentModel}
                onChange={setSelectedActivityCoefficientModel}
                aria-label="System Options"
                className="mt-4 bg-white px-4 py-2"
              >
                {activityCoefficientModels.map(activityCoefficentModel => (
                  <Field key={activityCoefficentModel} className="flex items-center gap-2 py-2">
                    <Label className="flex flex-auto text-lg">
                      {activityCoefficentModel}
                      <Show show={activityCoefficentModel != 'NIST-UNIFAC'}>
                        <FcInfo
                          className="ml-2 cursor-pointer text-sm"
                          onClick={() => window.open(`/document?scroll=${activityCoefficentModel}`, '_blank')}
                        />
                      </Show>
                    </Label>
                    <Radio
                      value={activityCoefficentModel}
                      className="group flex size-5 cursor-pointer items-center justify-center rounded-full border border-black bg-white data-[checked]:bg-black"
                    >
                      <span className="invisible size-2 rounded-full bg-white group-data-[checked]:visible" />
                    </Radio>
                  </Field>
                ))}
              </RadioGroup>
            </div>
            <div>
              <h3 className="text-xl font-bold">Vapor Pressure Model</h3>
              <RadioGroup
                value={selectedVaporPressureModel}
                onChange={setSelecetedVaporPressureModel}
                aria-label="System Options"
                className="mt-4 bg-white px-4 py-2"
              >
                {vaporPressureModels.map(vaporPressureModel => (
                  <Field key={vaporPressureModel} className="flex items-center gap-2 py-2">
                    <Label className="flex flex-auto text-lg">{vaporPressureModel}</Label>
                    <Radio
                      value={vaporPressureModel}
                      className="group flex size-5 cursor-pointer items-center justify-center rounded-full border border-black bg-white data-[checked]:bg-black"
                    >
                      <span className="invisible size-2 rounded-full bg-white group-data-[checked]:visible" />
                    </Radio>
                  </Field>
                ))}
              </RadioGroup>
            </div>
          </Show>
          <h5 className="mt-12 text-xl font-semibold">Search Examples</h5>
          <div className="mt-2 flex w-fit flex-wrap border border-gray-500 bg-white">
            <div className="px-4 py-2 text-center text-slate-900">
              <div className="text-lg font-bold">InChI</div>
              <div className="text-lg">InChI=1S/C6H6O2/c7-5-3-1-2-4-6&#40;5&#41;8/h1-4,7-8H</div>
            </div>
            <div className="px-4 py-2 text-center text-slate-900">
              <div className="text-lg font-bold">IUPAC Name</div>
              <div className="text-lg">1,2-benzenediol</div>
            </div>
            <div className="px-4 py-2 text-center text-slate-900">
              <div className="text-lg font-bold">Name</div>
              <div className="text-lg">Catechol</div>
            </div>
            <div className="px-4 py-2 text-center text-slate-900">
              <div className="text-lg font-bold">CASRN</div>
              <div className="text-lg">120-80-9</div>
            </div>
            <div className="px-4 py-2 text-center text-slate-900">
              <div className="text-lg font-bold">SMILES</div>
              <div className="text-lg">Oc1ccccc1O</div>
            </div>
          </div>
        </form>

        <NoWarranty />
      </ContentContainer>

      <KetcherModal open={showKetcher} setOpen={setShowKetcher} handleSubmit={modalClick} ref={ketcherRef} />
    </>
  );
}
