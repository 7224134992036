export default function Show(props) {
  const { children, show, as, className, ...otherProps } = props;
  const Component = as;

  if (show) {
    if (Component) {
      return (
        <Component className={className} {...otherProps}>
          {children}
        </Component>
      );
    } else return children;
  } else return null;
}
