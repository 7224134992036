import { useQuery } from '@tanstack/react-query';

import { queryErrorHandler } from 'utils/error-handler';

function useCustomQuery(queryObject) {
  const query = useQuery(queryObject);

  if (query.isError) queryErrorHandler(query);

  return query;
}

// >>> 예제
// const mutationFn = async () => {};
// const mutate = useMutation({ mutationFn });
// return (
//   <button
//     onClick={() => {
//       mutate.mutate(data);
//     }}
//   />
// );
// <<<

export { useCustomQuery };
