import React, { useLayoutEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import Router from 'routes/router';
import { LocationContextProvider } from 'contexts/LocationContext';

import 'react-toastify/dist/ReactToastify.css';
import 'styles/global.css';
import 'styles/properties.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 0,
      retry: 0,
      throwOnError: false,
    },
  },
});

const LayoutEffect = ({ children }) => {
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return children;
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <QueryClientProvider client={queryClient}>
    <LocationContextProvider>
      <BrowserRouter>
        <LayoutEffect>
          <Router />
        </LayoutEffect>
      </BrowserRouter>
    </LocationContextProvider>
    <ToastContainer
      pauseOnFocusLoss
      closeOnClick
      position="top-right"
      autoClose={7000}
      pauseOnHover={false}
    />
  </QueryClientProvider>,
);
