import { httpErrorList } from 'utils/http-errors';

function findDefinedError(res) {
  const errorCode = res.error_code;
  const error = httpErrorList.find(e => e.code === errorCode);

  return (
    error ?? {
      code: undefined,
      toast: false,
      toast_message: undefined,
      toast_instruction: undefined,
      error_page_title: res.error_code,
      error_page_instruction: 'Please contact the staff for support.',
    }
  );
}

function queryErrorHandler(query) {
  try {
    const res = query.error.response.data;
    const error = findDefinedError(res);
    if (error.toast) query.error.toast = error.toast;
    if (error.toast_message) query.error.toast_message = error.toast_message;
    if (error.toast_instruction) query.error.toast_instruction = error.toast_instruction;
    if (error.error_page_title) query.error.error_page_title = error.error_page_title;
    if (error.error_page_instruction) query.error.error_page_instruction = error.error_page_instruction;
    if (res.error_uuid) query.error.uuid = res.error_uuid;
  } catch {
    query.error.toast = false;
    query.error.error_page_title = 'Unable to reach the server';
    query.error.error_page_instruction = 'Please check your network settings or try again in a few moments.';
  }
}

export { queryErrorHandler };
