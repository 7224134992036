// Modules
import { Route, Routes } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

// Layouts
import { MainApp } from 'layouts/MainApp';
import { OnlyKorea } from 'layouts/OnlyKorea';

// Pages
import PageNotFound from 'routes/404';

// Home
import Home from 'routes/home';
import AboutUs from 'routes/about';
import Contact from 'routes/contact';
import Citation from 'routes/citation';
import Document from 'routes/document';

// Search
import SearchPure from 'routes/search/pure';
import SearchPureID from 'routes/search/pure/id';
import SearchPureData from 'routes/search/pure/data';
import SearchBinaryVLE from 'routes/search/binary-VLE';
import SearchBinaryVLEID from 'routes/search/binary-VLE/id';
import SearchBinaryVLEData from 'routes/search/binary-VLE/data';
import SearchUNIFACParameters from 'routes/search/NIST-UNIFAC';

// Calculate
import UnitConversion from 'routes/calculate/unit-conversion';
import CalculatePure from 'routes/calculate/pure';
import CalculatePureResult from 'routes/calculate/pure/result';
import CalculateBinaryVLE from 'routes/calculate/binary-VLE';
import CalculateBinaryVLEResult from 'routes/calculate/binary-VLE/result';

export default function Router() {
  return (
    <Routes>
      <Route element={<MainApp />}>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/citation" element={<Citation />} />
        <Route path="/document" element={<Document />} />
        <Route path="/search">
          <Route path="pure" element={<SearchPure />} />
          <Route path="pure/id" element={<SearchPureID />} />
          <Route path="pure/:id" element={<SearchPureData />} />
          <Route element={<OnlyKorea />}>
            <Route path="binary-vle" element={<SearchBinaryVLE />} />
            <Route path="binary-vle/id" element={<SearchBinaryVLEID />} />
            <Route path="binary-vle/:id" element={<SearchBinaryVLEData />} />
          </Route>
          <Route path="unifac-parameters" element={<SearchUNIFACParameters />} />
        </Route>
        <Route path="/calculate">
          <Route path="pure" element={<CalculatePure />} />
          <Route path="pure/:id" element={<CalculatePureResult />} />
          <Route path="binary-VLE" element={<CalculateBinaryVLE />} />
          <Route path="binary-VLE/result" element={<CalculateBinaryVLEResult />} />
          <Route path="unit-conversion" element={<UnitConversion />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
}
