import Reference from 'components/Reference';

export default function COSMOSACDocument() {
  return (
    <div className="max-w-screen-lg text-lg">
      <div>
        The&nbsp;
        <span className="font-semibold">
          COnductor-like Screening MOdel - Segmented Activity Coefficient &#40;COSMO-SAC&#41;
        </span>
        &nbsp;is a thermodynamic model that calculates the properties of fluids and solutions using quantum
        chemical data. It is based on the COSMO calculation, which was developed to accurately predict
        solvation phenomena by considering the molecular surface polarization charges when a molecule is
        placed in a virtual conductor environment.
      </div>
      <div className="mt-2">
        Unlike group contribution methods such as UNIFAC, which rely on empirical parameterizations for
        specific chemical groups, COSMO-SAC is grounded in quantum mechanical calculations. As a result, it
        possesses predictive power outside the parametrization set, allowing it to accurately predict
        thermodynamic properties for a wider range of compounds.
      </div>
      <div className="mt-2">
        The re-parameterization by Kang and his group has further enhanced the accuracy of COSMO-SAC,
        particularly for compounds containing carboxylic acid groups. Their revised model shows better
        agreement with experimental thermodynamic properties, broadening the applicability of COSMO-SAC to
        more complex chemical systems.
      </div>
      <img className="my-8" src="/images/document/COSMO-SAC/graph.jpg" />
      <div className="mt-2">
        KDB contains approximately 5,000 pre-calculated COSMO results, enabling fast and accurate
        thermodynamic property calculations. By leveraging this extensive database, users can efficiently
        predict phase equilibria, solubility, and other thermodynamic properties without performing
        time-consuming quantum chemical calculations.
      </div>
      <div className="mt-4 space-y-0.5">
        <div className="text-lg font-semibold">Reference</div>
        <Reference index="1">
          Klamt, Andreas. "Conductor-like screening model for real solvents: a new approach to the
          quantitative calculation of solvation phenomena." The Journal of Physical Chemistry 99.7
          &#40;1995&#41;: 2224-2235.
        </Reference>
        <Reference index="2">
          Lin, Shiang-Tai, and Stanley I. Sandler. "A priori phase equilibrium prediction from a segment
          contribution solvation model." Industrial & engineering chemistry research 41.5 &#40;2002&#41;:
          899-913.
        </Reference>
        <Reference index="3">
          Kang, Sung Shin, Jonghwi Lee, and Jeong Won Kang. "An extended COSMO-SAC method for the prediction
          of carboxylic acid solubility." Fluid Phase Equilibria 521 &#40;2020&#41;: 112673.
        </Reference>
      </div>
    </div>
  );
}
