function GradeTable({ children }) {
  return <div className="w-fit border border-black">{children}</div>;
}

function GradeInnerTable({ children }) {
  return <div className="flex gap-x-2 border border-black py-1">{children}</div>;
}

function GradeName({ children }) {
  return <div className="flex w-60 items-center justify-center py-2 text-lg">{children}</div>;
}

function GradeInt({ children }) {
  return <div className="flex items-center justify-center px-4 py-2">{children}</div>;
}

function GradeItem({ children }) {
  return <div className="flex items-center gap-x-2">{children}</div>;
}

function GradeLabel({ children }) {
  return <div className="w-28">{children}</div>;
}

function GradeDescription({ children }) {
  return <div className="px-1 py-0.5">{children}</div>;
}

function BoilingPointGradeTable() {
  return (
    <GradeTable>
      <GradeInnerTable>
        <GradeName>Certified Standard Reference</GradeName>
        <GradeInt>5</GradeInt>
        <div>
          <GradeItem>
            <GradeLabel>Accuracy</GradeLabel>
            <GradeDescription>Uncertainty is less than or equal to 1K.</GradeDescription>
          </GradeItem>
          <GradeItem>
            <GradeLabel>Reproducibility</GradeLabel>
            <GradeDescription>More than 50 different experimental values</GradeDescription>
          </GradeItem>
          <GradeItem>
            <GradeLabel>Predictability</GradeLabel>
            <GradeDescription>Matches the results of machine learning within 1%.</GradeDescription>
          </GradeItem>
          <GradeItem>
            <GradeLabel>Consistency</GradeLabel>
            <GradeDescription>
              Matches the results of other databases/theoretical prediction calculations within 2%.
            </GradeDescription>
          </GradeItem>
          <GradeItem>
            <GradeLabel>Validation</GradeLabel>
            <GradeDescription>Validated by experts</GradeDescription>
          </GradeItem>
        </div>
      </GradeInnerTable>
      <GradeInnerTable>
        <GradeName>Verified Standard Reference</GradeName>
        <GradeInt>4</GradeInt>
        <div>
          <GradeItem>
            <GradeLabel>Accuracy</GradeLabel>
            <GradeDescription>Uncertainty is less than or equal to 1%</GradeDescription>
          </GradeItem>
          <GradeItem>
            <GradeLabel>Reproducibility</GradeLabel>
            <GradeDescription>More than 10 different experimental values</GradeDescription>
          </GradeItem>
          <GradeItem>
            <GradeLabel>Predictability</GradeLabel>
            <GradeDescription>
              Matches the results of other databases/theoretical prediction calculations within 5%
            </GradeDescription>
          </GradeItem>
        </div>
      </GradeInnerTable>
      <GradeInnerTable>
        <GradeName>Reliable Standard Reference</GradeName>
        <GradeInt>3</GradeInt>
        <div>
          <GradeItem>
            <GradeLabel>Accuracy</GradeLabel>
            <GradeDescription>Uncertainty is less than or equal to 5%</GradeDescription>
          </GradeItem>
          <GradeItem>
            <GradeLabel>Reproducibility</GradeLabel>
            <GradeDescription>More than 3 different experimental values</GradeDescription>
          </GradeItem>
          <GradeItem>
            <GradeLabel>Predictability</GradeLabel>
            <GradeDescription>
              Matches the results of other databases/theoretical prediction calculations within 10%
            </GradeDescription>
          </GradeItem>
        </div>
      </GradeInnerTable>
      <GradeInnerTable>
        <GradeName>Reference Data</GradeName>
        <GradeInt>2</GradeInt>
        <GradeItem>
          <GradeLabel>Reproducibility</GradeLabel>
          <GradeDescription>The average of three or fewer values</GradeDescription>
        </GradeItem>
      </GradeInnerTable>
    </GradeTable>
  );
}

function OtherGradeTable() {
  return (
    <GradeTable>
      <GradeInnerTable>
        <GradeName>Certified Standard Reference</GradeName>
        <GradeInt>5</GradeInt>
        <div>
          <GradeItem>
            <GradeLabel>Accuracy</GradeLabel>
            <GradeDescription>Uncertainty is under 5%.</GradeDescription>
          </GradeItem>
          <GradeItem>
            <GradeLabel>Reproducibility</GradeLabel>
            <GradeDescription>More than 10 different experimental values</GradeDescription>
          </GradeItem>
          <GradeItem>
            <GradeLabel>Predictability</GradeLabel>
            <GradeDescription>Matches the results of machine learning within 1%.</GradeDescription>
          </GradeItem>
          <GradeItem>
            <GradeLabel>Consistency</GradeLabel>
            <GradeDescription>
              Matches the results of other databases/theoretical prediction calculations within 5%.
            </GradeDescription>
          </GradeItem>
          <GradeItem>
            <GradeLabel>Validation</GradeLabel>
            <GradeDescription>Validated by experts</GradeDescription>
          </GradeItem>
        </div>
      </GradeInnerTable>
      <GradeInnerTable>
        <GradeName>Verified Standard Reference</GradeName>
        <GradeInt>4</GradeInt>
        <div>
          <GradeItem>
            <GradeLabel>Accuracy</GradeLabel>
            <GradeDescription>Uncertainty is under 5%</GradeDescription>
          </GradeItem>
          <GradeItem>
            <GradeLabel>Reproducibility</GradeLabel>
            <GradeDescription>More than 5 different experimental values</GradeDescription>
          </GradeItem>
          <GradeItem>
            <GradeLabel>Predictability</GradeLabel>
            <GradeDescription>
              Matches the results of other databases/theoretical prediction calculations within 5%
            </GradeDescription>
          </GradeItem>
        </div>
      </GradeInnerTable>
      <GradeInnerTable>
        <GradeName>Reliable Standard Reference</GradeName>
        <GradeInt>3</GradeInt>
        <div>
          <GradeItem>
            <GradeLabel>Accuracy</GradeLabel>
            <GradeDescription>Uncertainty is under 10%</GradeDescription>
          </GradeItem>
          <GradeItem>
            <GradeLabel>Reproducibility</GradeLabel>
            <GradeDescription>More than 3 different experimental values</GradeDescription>
          </GradeItem>
          <GradeItem>
            <GradeLabel>Predictability</GradeLabel>
            <GradeDescription>
              Matches the results of other databases/theoretical prediction calculations within 10%
            </GradeDescription>
          </GradeItem>
        </div>
      </GradeInnerTable>
      <GradeInnerTable>
        <GradeName>Reference Data</GradeName>
        <GradeInt>2</GradeInt>
        <GradeItem>
          <GradeLabel>Reproducibility</GradeLabel>
          <GradeDescription>The average of three or fewer values</GradeDescription>
        </GradeItem>
      </GradeInnerTable>
    </GradeTable>
  );
}

export default function GradeDocument() {
  return (
    <div className="space-y-4 text-lg">
      <div className="max-w-screen-lg">
        Thermodynamic property data have been reported in the literature since the 1800s and compiled into
        databases. Although the abundance of data allows for more accurate property estimations, older
        literature values present certain issues. As experimental methods have advanced, some older values are
        less accurate, yet they still exist in databases and can influence the estimation of recommended
        values. Additionally, many older data were reported before the concept of uncertainty was established,
        resulting in a lack of uncertainty information. Even among recently published papers, different
        experimental methods lead to varying levels of uncertainty. KDB address these issues, with several
        statistical techniques for the evaluation, with the final grading determined through expert.
      </div>
      <div>For boiling points, the following grading system is applied</div>
      <BoilingPointGradeTable />
      <div className="pt-4">For other properties, the following grading system is applied</div>
      <OtherGradeTable />
    </div>
  );
}
