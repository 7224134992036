import { Link } from 'react-router-dom';
import { FaRegCopyright } from 'react-icons/fa';

import { LogoKDB, LogoMDL, LogoKoreaUniv } from 'components/Logo';

const footerMenus = [
  { name: 'About us', to: '/about' },
  { name: 'Contact', to: '/contact' },
  { name: 'Citation', to: '/citation' },
];

export default function Footer() {
  return (
    <footer className="bg-slate-200">
      {/* PC Size */}
      <div className="content-x py-4 max-sm:hidden">
        <div className="mx-auto w-fit space-x-14 text-xl">
          {footerMenus.map(menu => (
            <Link className="px-2" to={menu.to} key={menu.to}>
              {menu.name}
            </Link>
          ))}
        </div>
        <LogoKDB subtitle={false} className="mx-auto mb-4 mt-6 text-slate-600" />
        <div className="col-span-3 flex items-center justify-center font-light">
          <p>2025</p>
          <FaRegCopyright className="ml-1 mr-2" />
          <p>MDL all right reserved</p>
        </div>
      </div>

      {/* Phone Size */}
      <div className="content-x space-y-4 py-6 sm:hidden">
        <div className="mx-auto w-fit space-x-4 text-lg">
          {footerMenus.map(menu => (
            <Link className="px-2" to={menu.to} key={menu.to}>
              {menu.name}
            </Link>
          ))}
        </div>
        <div className="flex flex-col items-center justify-center space-y-4">
          <LogoKDB subtitle={false} className="mx-auto text-slate-600" />
        </div>
        <div className="flex items-center justify-center font-light">
          <p>2025</p>
          <FaRegCopyright className="ml-1 mr-2" />
          <p>MDL all right reserved</p>
        </div>
      </div>
    </footer>
  );
}
