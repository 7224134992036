import { Link } from 'react-router-dom';
import { BsDot } from 'react-icons/bs';

import Show from 'components/Show';
import { useLocationContext } from 'contexts/LocationContext';
import { LogoNCSRD, LogoMDL, LogoKoreaUniv } from 'components/Logo';
import { ContentContainer } from 'components/Content';

import NoWarranty from 'components/NoWarranty';

export default function Home() {
  const { isKorea } = useLocationContext();

  return (
    <ContentContainer>
      <h1 className="text-5xl font-bold">KDB Online Service</h1>
      <h3 className="text-3xl font-semibold">Korea Thermophysical Properties Data Bank</h3>
      <p className="mt-8 max-w-screen-lg text-xl">
        KDB &#40;Korea Thermophysical Properties Data Bank&#41; is a national database providing reliable
        thermodynamic property data for various compounds. Established by Korea University’s Thermophysical
        Properties Data Center in the 1990s. It is currently managed by Professor Kang and his Molecular
        Design Laboratory &#40;MDL&#41; at Korea University.
      </p>
      <p className="mt-4 max-w-screen-lg text-xl">
        KDB supports education, research, and industrial applications in chemical engineering. The
        standardized reference data is collected from scientific journals, through international collaboration
        with national organizations and university. When experimental data is unavailable, advanced methods
        such as quantum mechanics and machine learning are used.
      </p>
      <Link to="/about" className="click-text mb-8 mt-4 block text-xl">
        <span className="text-black">-&nbsp;&nbsp;</span>Learn more about KDB
      </Link>
      <section className="border-y border-black py-8" aria-label="Online Services">
        <h4 className="text-3xl font-bold">Online Services</h4>
        <Link to="/search/pure" className="mt-4 block text-xl text-blue-800 hover:text-blue-500">
          <span className="text-black">-&nbsp;&nbsp;</span>Search Pure Property Data
        </Link>
        <Show show={isKorea}>
          <Link to="/search/binary-vle" className="mt-2 block text-xl text-blue-800 hover:text-blue-500">
            <span className="text-black">-&nbsp;&nbsp;</span>Search Binary VLE Data
          </Link>
        </Show>
        <Link to="/search/unifac-parameters" className="mt-2 block text-xl text-blue-800 hover:text-blue-500">
          <span className="text-black">-&nbsp;&nbsp;</span>NIST-UNIFAC Parameters
        </Link>
        <Link to="/calculate/pure" className="mt-2 block text-xl text-blue-800 hover:text-blue-500">
          <span className="text-black">-&nbsp;&nbsp;</span>Calculate Pure Properties
        </Link>
        <Link to="/calculate/binary-vle" className="mt-2 block text-xl text-blue-800 hover:text-blue-500">
          <span className="text-black">-&nbsp;&nbsp;</span>Calculate Binary VLE
        </Link>
      </section>
      <section className="border-b border-black py-8" aria-label="KDB contains">
        <h4 className="text-3xl font-bold">KDB contains</h4>
        <h5 className="mt-2 flex items-center text-xl font-semibold">
          <BsDot className="mr-2" />
          Phase Transition Properties
        </h5>
        <p className="ml-7 text-xl font-light">-&nbsp;&nbsp;Normal Boiling Point</p>
        <p className="ml-7 text-xl font-light">-&nbsp;&nbsp;Melting Point</p>
        <p className="ml-7 text-xl font-light">-&nbsp;&nbsp;Triple Point Temperature</p>
        <p className="ml-7 text-xl font-light">-&nbsp;&nbsp;Triple Point Pressure</p>
        <p className="ml-7 text-xl font-light">-&nbsp;&nbsp;Vapor Pressure Coefficient</p>
        <h5 className="mt-2 flex items-center text-xl font-semibold">
          <BsDot className="mr-2" />
          Phase Critical Properties
        </h5>
        <p className="ml-7 text-xl font-light">-&nbsp;&nbsp;Critical Temperature</p>
        <p className="ml-7 text-xl font-light">-&nbsp;&nbsp;Critical Pressure</p>
        <p className="ml-7 text-xl font-light">-&nbsp;&nbsp;Critical Volume</p>
        <h5 className="mt-2 flex items-center text-xl font-semibold">
          <BsDot className="mr-2" />
          Binary Vapor Liquid Data
        </h5>
        <h5 className="mt-2 flex items-center text-xl font-semibold">
          <BsDot className="mr-2" />
          Advanced Pure Property Estimation
        </h5>
        <p className="ml-7 text-xl font-light">-&nbsp;&nbsp;Normal Boiling Point</p>
        <p className="ml-7 text-xl font-light">-&nbsp;&nbsp;Melting Point</p>
        <p className="ml-7 text-xl font-light">-&nbsp;&nbsp;Critical Temperature</p>
        <p className="ml-7 text-xl font-light">-&nbsp;&nbsp;Critical Pressure</p>
        <p className="ml-7 text-xl font-light">-&nbsp;&nbsp;Critical Volume</p>
        <p className="ml-7 text-xl font-light">-&nbsp;&nbsp;Ideal Gas Heat of Formation</p>
        <p className="ml-7 text-xl font-light">-&nbsp;&nbsp;Heat of Fusion</p>
        <p className="ml-7 text-xl font-light">-&nbsp;&nbsp;Vapor Pressure</p>
        <p className="ml-7 text-xl font-light">-&nbsp;&nbsp;Flash Point</p>
        <h5 className="mt-2 flex items-center text-xl font-semibold">
          <BsDot className="mr-2" />
          Binary Vapor Liquid Equilibria Calculation
        </h5>
        <p className="ml-7 text-xl font-light">-&nbsp;&nbsp;NIST-UNIFAC</p>
        <p className="ml-7 text-xl font-light">-&nbsp;&nbsp;COSMO-SAC &#40;2019 ver&#41;</p>
        <p className="ml-7 text-xl font-light">-&nbsp;&nbsp;COSMO-SAC-ML</p>
        <div className="mt-8 text-xl italic text-gray-600">
          ※&nbsp;&nbsp;Some services might be limited outside of South Korea. Sorry for the inconvenience.
        </div>
      </section>
      <section className="border-b border-black py-8" aria-label="updates">
        <h4 className="mb-1 text-3xl font-bold">Updates</h4>
        <p>Last major update to the site: January, 2025</p>
      </section>
      <NoWarranty />
      <section className="mb-16 mt-24" aria-label="organiztions">
        <h1 className="text-center text-5xl font-bold">Organizations</h1>
        <div className="mt-16 grid items-center justify-center gap-x-8 gap-y-16 md:grid-cols-2 xl:grid-cols-3">
          <LogoNCSRD className="mx-auto" />
          <LogoMDL className="mx-auto" />
          <LogoKoreaUniv className="mx-auto" />
        </div>
      </section>
    </ContentContainer>
  );
}
