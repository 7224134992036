import { useSearchParams } from 'react-router-dom';
import { FiChevronsLeft, FiChevronsRight } from 'react-icons/fi';

export default function Pagination({ numData }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get('page'), 10) || 1;
  const numPages = Math.ceil(numData / 10);
  const range = 2;

  // GET PAGINATION ARRAY
  let start = currentPage - range;
  let end = currentPage + range;
  if (start < 1) {
    end += 1 - start;
    start = 1;
  }
  if (end > numPages) {
    start -= end - numPages;
    end = numPages;
  }
  start = Math.max(1, start);
  end = Math.min(numPages, end);
  const paginationArray = Array.from({ length: end - start + 1 }, (_, i) => start + i);

  const updateQueryParam = (key, value) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set(key, value);
    setSearchParams(newParams);
  };

  return (
    <div className="mx-auto flex w-fit space-x-8 text-xl">
      {start > 1 && (
        <button
          onClick={() => {
            updateQueryParam('page', Math.max(currentPage - range * 2, 1));
          }}
        >
          <FiChevronsLeft className="mt-0.5" />
        </button>
      )}
      {paginationArray.map(page => (
        <button
          key={`page_${page}`}
          onClick={() => {
            updateQueryParam('page', page);
          }}
          className={currentPage === page ? 'font-bold' : null}
        >
          {page}
        </button>
      ))}
      {end < numPages && (
        <button
          onClick={() => {
            updateQueryParam('page', Math.min(currentPage + range * 2, numPages));
          }}
        >
          <FiChevronsRight className="mt-0.5" />
        </button>
      )}
    </div>
  );
}
