import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

import { useCustomQuery } from 'hooks/query-hooks';
import Show from 'components/Show';
import Base64Image from 'components/base/Base64Image';
import AsyncPage from 'components/AsyncPage';
import NoWarranty from 'components/NoWarranty';
import request from 'utils/http-request';

function calculatePureQuery(smiles) {
  const query = useCustomQuery({
    queryKey: ['calculate_pure_constants', smiles],
    queryFn: async () => {
      return await request({
        url: '/pure/calculate',
        method: 'GET',
        params: { compound: smiles },
      });
    },
  });

  return query;
}

export default function CalculatePureResult() {
  const navigate = useNavigate();
  const params = useParams();
  const calculatedResults = calculatePureQuery(params.id);
  const [vaporPressureData, setVaporPressureData] = useState(null);

  useEffect(() => {
    if (calculatedResults.isSuccess) {
      const tempData = calculatedResults.data?.data.properties.vapor_pressure[0].map(
        (temperature, index) => ({
          temperature: temperature,
          pressure: calculatedResults.data?.data.properties.vapor_pressure[1][index],
        }),
      );

      setVaporPressureData(tempData);
    }
  }, [calculatedResults.isSuccess]);

  return (
    <AsyncPage query={calculatedResults} className="content-x content-y" errorFn={() => navigate(-1)}>
      <div className="mb-4 text-4xl font-semibold">Calculated Results</div>
      <>
        <h2 className="property-h2">Component Structure</h2>
        <h3 className="property-h3">2D structure</h3>
        <div className="my-4 aspect-square w-full max-w-64 border border-black">
          <Base64Image className="w-full" src={calculatedResults.data?.data.image} />
        </div>
        <h3 className="property-h3">Canonical SMILES</h3>
        <p className="property-p">{params.id}</p>
      </>
      <>
        <h2 className="property-h2">Phase Transition Properties</h2>
        <h3 className="property-h3">Boiling Points</h3>
        <div className="property-p">
          <p>{calculatedResults.data?.data.properties.TBN[0].toPrecision(5)}</p>
          <p>±</p>
          <p>{calculatedResults.data?.data.properties.TBN[1].toPrecision(3)}</p>
          <p>K</p>
        </div>
        <h3 className="property-h3">Melting Points</h3>
        <div className="property-p">
          <p>{calculatedResults.data?.data.properties.TMN[0].toPrecision(5)}</p>
          <p>±</p>
          <p>{calculatedResults.data?.data.properties.TMN[1].toPrecision(3)}</p>
          <p>K</p>
        </div>
      </>
      <>
        <h2 className="property-h2">Critical Properties</h2>
        <h3 className="property-h3">Critical Temperature</h3>
        <div className="property-p">
          <p>{calculatedResults.data?.data.properties.TC[0].toPrecision(5)}</p>
          <p>±</p>
          <p>{calculatedResults.data?.data.properties.TC[1].toPrecision(3)}</p>
          <p>K</p>
        </div>
        <h3 className="property-h3">Critical Pressure</h3>
        <div className="property-p">
          <p>{calculatedResults.data?.data.properties.PC[0].toPrecision(5)}</p>
          <p>±</p>
          <p>{calculatedResults.data?.data.properties.PC[1].toPrecision(3)}</p>
          <p>kPa</p>
        </div>
        <h3 className="property-h3">Critical Volume</h3>
        <div className="property-p">
          <p>{calculatedResults.data?.data.properties.VC[0].toPrecision(5)}</p>
          <p>±</p>
          <p>{calculatedResults.data?.data.properties.VC[1].toPrecision(3)}</p>
          <p>L/mol</p>
        </div>
        <h3 className="mb-1 mt-2 border-b-2 border-slate-500 pb-1 text-xl font-semibold">Acentric Factor</h3>
        <div className="property-p">
          <p>{calculatedResults.data?.data.properties.omega[0].toPrecision(5)}</p>
          <p>±</p>
          <p>{calculatedResults.data?.data.properties.omega[1].toPrecision(3)}</p>
        </div>
      </>
      <>
        <h2 className="property-h2">Thermodynamic Properties</h2>
        <h3 className="property-h3">Heat of Foramtion &#40;Ideal Gas&#41;</h3>
        <div className="property-p">
          <p>{calculatedResults.data?.data.properties.HFORM[0].toPrecision(5)}</p>
          <p>±</p>
          <p>{calculatedResults.data?.data.properties.HFORM[1].toPrecision(3)}</p>
          <p>kJ/mol</p>
        </div>
        <h3 className="property-h3">Heat of Fusion</h3>
        <div className="property-p">
          <p>{calculatedResults.data?.data.properties.HFUS[0].toPrecision(5)}</p>
          <p>±</p>
          <p>{calculatedResults.data?.data.properties.HFUS[1].toPrecision(3)}</p>
          <p>kJ/mol</p>
        </div>
      </>
      <>
        <h2 className="property-h2">Temperature Dependent Property</h2>
        <h3 className="property-h3">Vapor Pressure &#40;kPa&#41;</h3>
        <Show show={vaporPressureData}>
          <div className="mt-6 aspect-square w-full max-w-[48rem] sm:aspect-video">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={vaporPressureData} margin={{ left: -20 }} layout="vertical">
                <CartesianGrid strokeDasharray="5 5" />
                <XAxis type="number" domain={[298, 'auto']}></XAxis>
                <YAxis type="number" domain={['auto', 'auto']} dataKey={'pressure'} reversed={true}></YAxis>
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey={'temperature'}
                  stroke="#8884d8"
                  name="Temperature (K)"
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div className="w-full max-w-[48rem] text-center">Temperature &#40;K&#41;</div>
        </Show>
      </>
      <>
        <h2 className="property-h2">Safety Hazard Properties</h2>
        <h3 className="property-h3">Flash Point</h3>
        <div className="property-p">
          <p>{calculatedResults.data?.data.properties.TF[0].toPrecision(5)}</p>
          <p>±</p>
          <p>{calculatedResults.data?.data.properties.TF[1].toPrecision(3)}</p>
          <p>K</p>
        </div>
      </>
      <>
        <h2 className="property-h2">Median Lethal Dose Toxicity Class &#40;Mouse&#41;</h2>
        <h3 className="property-h3">Inhalation</h3>
        <div className="property-p">
          <p>{calculatedResults.data?.data.properties.inhalation_toxicity}</p>
        </div>
        <h3 className="property-h3">Intraperitoneal</h3>
        <div className="property-p">
          <p>{calculatedResults.data?.data.properties.intraperitoneal_toxicity}</p>
        </div>
        <h3 className="property-h3">Intravenous</h3>
        <div className="property-p">
          <p>{calculatedResults.data?.data.properties.intravenous_toxicity}</p>
        </div>
        <h3 className="property-h3">Oral</h3>
        <div className="property-p">
          <p>{calculatedResults.data?.data.properties.oral_toxicity}</p>
        </div>
        <h3 className="property-h3">Dermal</h3>
        <div className="property-p">
          <p>{calculatedResults.data?.data.properties.dermal_toxicity}</p>
        </div>
        <h3 className="property-h3">Subcutaneous</h3>
        <div className="property-p">
          <p>{calculatedResults.data?.data.properties.subcutaneous_toxicity}</p>
        </div>
      </>
      <NoWarranty />
    </AsyncPage>
  );
}
