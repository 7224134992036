import Reference from 'components/Reference';

export default function COSMOSACMLDocument() {
  return (
    <div className="max-w-screen-lg text-lg">
      <div>
        COSMO-SAC-ML is an advanced thermodynamic model that integrates machine learning &#40;ML&#41;
        techniques with the COSMO-SAC framework. It is replaced quantum mechanic calculation to GC-GCN while
        significantly reducing the computational burden associated with conventional COSMO calculations.
      </div>
      <img className="my-4 max-w-screen-md" src="/images/document/COSMO-SAC-ML/graph.jpg" />
      <div className="mt-4 text-lg font-semibold">Reference</div>
      <Reference index="1">
        Ryu, Beom Chan, et al. "Group contribution based graph convolution network: predicting vapor–liquid
        equilibrium with cosmo-sac-ml." International Journal of Thermophysics 44.4 &#40;2023&#41;: 49.
      </Reference>
    </div>
  );
}
