import { useRef, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { VscSearch } from 'react-icons/vsc';

import { ContentTitle, ContentContainer } from 'components/Content';
import KetcherModal from 'components/KetcherModal';
import ComponentInput from 'components/ComponentInput';
import GCgcnGroups from 'components/GCgcnGroups';

export default function CalculatePure() {
  const ketcherRef = useRef();
  const navigate = useNavigate();
  const [showKetcher, setShowKetcher] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const modalClick = () => {
    ketcherRef.current.getSmiles().then(smiles => {
      setSearchValue(smiles);
      setShowKetcher(false);
      ketcherRef.current.setMolecule('');
    });
  };

  const onSubmit = e => {
    e.preventDefault();

    if (searchValue.length >= 1) {
      const urlQuery = encodeURIComponent(searchValue);
      navigate(urlQuery);
    }
  };

  return (
    <>
      <ContentContainer className="content-x py-12">
        <ContentTitle>Pure Property Calculator</ContentTitle>
        <form className="border-2 border-slate-400 bg-gray-50 p-4 sm:p-8" onSubmit={onSubmit}>
          <div className="flex">
            <ComponentInput
              className="h-14 w-full text-xl placeholder:text-gray-400"
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              placeholder="Insert SMILES format. (Example) CCO, c1ccccc1)"
            />
            <button type="submit" className="h-14 w-14 min-w-10 bg-gray-300 p-2">
              <VscSearch className="h-full w-full" />
            </button>
          </div>
          <button
            className="mt-4 text-xl font-semibold hover:text-blue-600"
            type="button"
            onClick={() => setShowKetcher(true)}
          >
            - Draw Structure
          </button>
        </form>

        {/* INFORMATION */}
        <div className="mb-4 mt-16 border-b-2 border-slate-500 pb-1 text-3xl font-bold">Calculation Help</div>
        <div className="max-w-screen-lg text-xl">
          KDB provides structure-based property calcualtion through Group Contribution based Graph Convolution
          Network &#40;GC-gcn&#41;. Component must be consist with at least 3 groups which are listed below.
          For the input, only SMILES notation is supported.
        </div>

        <div className="click-text mb-12 mt-4 text-xl">
          <span className="mr-2 text-black">-</span>For more details
        </div>

        <h3 className="property-h3 text-2xl">Supporting Groups</h3>
        <GCgcnGroups />
      </ContentContainer>

      <KetcherModal open={showKetcher} setOpen={setShowKetcher} handleSubmit={modalClick} ref={ketcherRef} />
    </>
  );
}
