export const citingJournals = [
  {
    MLA: 'Vopička, Ondřej, Marek Lanč, and Karel Friess. "Phenomenology of vapour sorption in polymers of intrinsic microporosity PIM-1 and PIM-EA-TB: envelopment of sorption isotherms." Current Opinion in Chemical Engineering 35 (2022): 100786.',
    href: 'https://www.sciencedirect.com/science/article/pii/S2211339821001180?via%3Dihub',
  },
  {
    MLA: 'Abouelnasr, Dana, and Kevin F. Loughlin. "Saturation loadings on 5A zeolite above and below the critical conditions: monatomic species data evaluation and modeling." Adsorption 27.8 (2021): 1263-1277.',
    href: 'https://link.springer.com/article/10.1007/s10450-021-00329-5',
  },
  {
    MLA: 'Gozálvez-Zafrilla, José M., J. Carlos García-Díaz, and Asunción Santafé-Moros. "Uncertainty quantification and global sensitivity analysis of continuous distillation considering the interaction of parameter uncertainty with feed variability." Chemical Engineering Science 235 (2021): 116509.',
    href: 'https://www.sciencedirect.com/science/article/pii/S0009250921000749?via%3Dihub',
  },
  {
    MLA: 'Dos Santos, Luana Cristina, et al. "Selective extraction of piceatannol from passiflora edulis by-products: Application of hsps strategy and inhibition of neurodegenerative enzymes." International journal of molecular sciences 22.12 (2021): 6248.',
    href: 'https://www.mdpi.com/1422-0067/22/12/6248',
  },
  {
    MLA: 'Zêzere, Bruno, et al. "Revisiting Tracer Liu-Silva-Macedo model for binary diffusion coefficient using the largest database of liquid and supercritical systems." The Journal of Supercritical Fluids 168 (2021): 105073.',
    href: 'https://www.sciencedirect.com/science/article/pii/S0896844620303247?via%3Dihub',
  },

  {
    MLA: 'Aniceto, José PS, Bruno Zêzere, and Carlos M. Silva. "Predictive models for the binary diffusion coefficient at infinite dilution in polar and nonpolar fluids." Materials 14.3 (2021): 542.',
    href: 'https://www.mdpi.com/1996-1944/14/3/542',
  },
  {
    MLA: 'Senegačnik, Matej, et al. "Dynamics of laser-induced cavitation bubble during expansion over sharp-edge geometry submerged in liquid–an inside view by diffuse illumination." Ultrasonics sonochemistry 73 (2021): 105460.',
    href: 'https://www.sciencedirect.com/science/article/pii/S1350417721000018?via%3Dihub',
  },
  {
    MLA: 'Michálek, Tomáš, and Zdeněk Hurák. "Position and orientation control at micro-and mesoscales using dielectrophoresis." arXiv preprint arXiv:2002.08764 (2020).',
    href: 'https://arxiv.org/abs/2002.08764',
  },
  {
    MLA: 'Michálek, Tomáš, et al. "Electrorotation of arbitrarily shaped micro-objects: modeling and experiments." IEEE/ASME Transactions on Mechatronics 25.2 (2019): 828-836.',
    href: 'https://ieeexplore.ieee.org/document/8933130',
  },
  {
    MLA: 'Zhao, Tom Y., and Neelesh A. Patankar. "The thermo-wetting instability driving Leidenfrost film collapse." Proceedings of the National Academy of Sciences 117.24 (2020): 13321-13328.',
    href: 'https://www.pnas.org/doi/full/10.1073/pnas.1917868117',
  },
  {
    MLA: '',
    href: '',
  },
  {
    MLA: '',
    href: '',
  },
  {
    MLA: '',
    href: '',
  },
  {
    MLA: '',
    href: '',
  },
  {
    MLA: '',
    href: '',
  },
  {
    MLA: '',
    href: '',
  },
  {
    MLA: '',
    href: '',
  },
];
