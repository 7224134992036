import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { VscSearch } from 'react-icons/vsc';

import { ContentContainer, ContentTitle } from 'components/Content';
import KetcherModal from 'components/KetcherModal';
import ComponentInput from 'components/ComponentInput';
import NoWarranty from 'components/NoWarranty';

export default function CalculatePure() {
  const ketcherRef = useRef();
  const navigate = useNavigate();
  const [showKetcher, setShowKetcher] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const modalClick = () => {
    ketcherRef.current.getSmiles().then(smiles => {
      setSearchValue(smiles);
      setShowKetcher(false);
      ketcherRef.current.setMolecule('');
    });
  };

  const onSubmit = e => {
    e.preventDefault();

    const searchQuery = encodeURIComponent(searchValue);
    if (searchValue.length > 1) {
      navigate(`/search/pure/id?query=${searchQuery}`);
    }
  };

  return (
    <>
      <ContentContainer>
        <ContentTitle>Search Pure Property Data</ContentTitle>
        <form className="border-2 border-slate-400 bg-gray-50 p-4 sm:p-8 lg:p-12" onSubmit={onSubmit}>
          <div className="flex">
            <ComponentInput
              className="h-14 w-full text-xl"
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
            />
            <button type="submit" className="h-14 w-14 min-w-10 bg-gray-300 p-2">
              <VscSearch className="h-full w-full" />
            </button>
          </div>
          <button
            className="mt-2 text-xl font-semibold hover:text-blue-500"
            type="button"
            onClick={() => setShowKetcher(true)}
          >
            <span className="text-black">-&nbsp;&nbsp;</span>Draw Structure
          </button>
          <h5 className="mt-16 text-xl font-semibold">Search Examples</h5>
          <div className="mt-2 flex w-fit flex-wrap border border-gray-500 bg-white">
            <div className="px-4 py-2 text-center text-slate-900">
              <div className="text-lg font-bold">InChI</div>
              <div className="text-lg">InChI=1S/C6H6O2/c7-5-3-1-2-4-6&#40;5&#41;8/h1-4,7-8H</div>
            </div>
            <div className="px-4 py-2 text-center text-slate-900">
              <div className="text-lg font-bold">IUPAC Name</div>
              <div className="text-lg">1,2-benzenediol</div>
            </div>
            <div className="px-4 py-2 text-center text-slate-900">
              <div className="text-lg font-bold">Name</div>
              <div className="text-lg">Catechol</div>
            </div>
            <div className="px-4 py-2 text-center text-slate-900">
              <div className="text-lg font-bold">CASRN</div>
              <div className="text-lg">120-80-9</div>
            </div>
            <div className="px-4 py-2 text-center text-slate-900">
              <div className="text-lg font-bold">SMILES</div>
              <div className="text-lg">Oc1ccccc1O</div>
            </div>
          </div>
        </form>
        <NoWarranty />
      </ContentContainer>

      <KetcherModal open={showKetcher} setOpen={setShowKetcher} handleSubmit={modalClick} ref={ketcherRef} />
    </>
  );
}
