import { twMerge } from 'tailwind-merge';

export default function Reference({ children, className, index }) {
  return (
    <div className={twMerge('flex gap-x-2', className)}>
      <div>&#91;{index}&#93;</div>
      <div>{children}</div>
    </div>
  );
}
