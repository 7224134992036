import { useEffect } from 'react';
import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useCustomQuery } from 'hooks/query-hooks';
import { ContentContainer, ContentTitle } from 'components/Content';
import { capitalizeFirstLetter } from 'utils/data-treatment';
import request from 'utils/http-request';
import Base64Image from 'components/base/Base64Image';
import AsyncPage from 'components/AsyncPage';

function searchComponent(param) {
  const query = useCustomQuery({
    queryKey: ['search_pure_compound', param],
    queryFn: async () => {
      return await request({
        url: '/pure/search',
        method: 'GET',
        params: { compound: param },
      });
    },
  });

  return query;
}

function SearchContainer({ query_data }) {
  return (
    <Link
      className="mb-8 flex flex-col gap-6 border-2 p-4 max-sm:items-center sm:flex-row"
      to={`/search/pure/${query_data.compound_id}`}
    >
      <div className="aspect-square w-full max-w-48 border-2 p-4">
        <Base64Image src={query_data.image} />
      </div>
      <div className="-space-y-1">
        <div className="mb-2 text-4xl font-semibold max-sm:text-center">{capitalizeFirstLetter(query_data.name)}</div>
        <div className="flex gap-x-2 text-lg">
          <div className="whitespace-nowrap">CASRN : </div>
          <div>{query_data.CASRN}</div>
        </div>
        <div className="flex gap-x-2 text-lg">
          <div className="whitespace-nowrap">Forumla : </div>
          <div>{query_data.formula}</div>
        </div>
        <div className="flex gap-x-2 text-lg">
          <div className="whitespace-nowrap">Molecular Weight : </div>
          <div>{query_data.molecular_weight}</div>
        </div>
        <div className="flex gap-x-2 text-lg">
          <div className="whitespace-nowrap">SMILES : </div>
          <div>{query_data.SMILES}</div>
        </div>
        <div className="flex gap-x-2 text-lg">
          <div className="whitespace-nowrap">InChI : </div>
          <div>{query_data.InChI}</div>
        </div>
        <div className="flex gap-x-2 text-lg">
          <div className="whitespace-nowrap">InChIKey : </div>
          <div>{query_data.InChIKey}</div>
        </div>
      </div>
    </Link>
  );
}

export default function SearchPureID() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const compound_query = searchParams.get('query');
  const searchResults = searchComponent(compound_query);

  useEffect(() => {
    if (searchResults.data?.data.length === 1) navigate(`/search/pure/${searchResults.data?.data[0].compound_id}`);
    else if (searchResults.data?.data.length === 0) {
      toast.error('No data has been found');
      navigate('/search/pure');
    }
  }, [searchResults.data?.data.length]);
  return (
    <AsyncPage query={searchResults}>
      <ContentContainer>
        <ContentTitle>
          Found {searchResults.data?.data.length} Results
          <div className="pt-1 text-xl font-normal">
            for <span className="font-bold">{compound_query}</span>
          </div>
        </ContentTitle>
        {searchResults.data?.data.map(query_data => (
          <SearchContainer query_data={query_data} key={query_data.CASRN} />
        ))}
      </ContentContainer>
    </AsyncPage>
  );
}
