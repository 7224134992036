import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ComponentInput from 'components/ComponentInput';
import NoWarranty from 'components/NoWarranty';
import KetcherModal from 'components/KetcherModal';
import { ContentContainer, ContentTitle } from 'components/Content';

export default function SearchBinaryVLE() {
  const ketcherRef = useRef();
  const navigate = useNavigate();
  const [showKetcher, setShowKetcher] = useState(false);
  const [component1, setComponent1] = useState('');
  const [component2, setComponent2] = useState('');
  const [isComponent1, setIsComponent1] = useState(true);

  const modalClick = () => {
    if (isComponent1) {
      ketcherRef.current.getSmiles().then(smiles => {
        setComponent1(smiles);
        ketcherRef.current.setMolecule('');
        setShowKetcher(false);
      });
    } else {
      ketcherRef.current.getSmiles().then(smiles => {
        setComponent2(smiles);
        ketcherRef.current.setMolecule('');
        setShowKetcher(false);
      });
    }
  };

  const onSubmit = e => {
    e.preventDefault();

    if (component1.length >= 1 && component2.length >= 1) {
      const component1Query = encodeURIComponent(component1);
      const component2Query = encodeURIComponent(component2);
      navigate(`/search/binary-vle/id?cmp1=${component1Query}&cmp2=${component2Query}&page=1`);
    }
  };

  return (
    <>
      <ContentContainer>
        <ContentTitle>Search Binary VLE Data</ContentTitle>
        <form className="border-2 border-slate-400 bg-gray-50 p-4 sm:p-8" onSubmit={onSubmit}>
          <div className="mb-2 text-xl font-semibold">Component 1</div>
          <ComponentInput
            className="h-14 w-full text-xl placeholder:text-gray-400"
            value={component1}
            onChange={e => setComponent1(e.target.value)}
          />
          <button
            className="mt-2 text-xl font-semibold hover:text-blue-500"
            type="button"
            onClick={() => {
              setIsComponent1(true);
              setShowKetcher(true);
            }}
          >
            <span className="text-black">-&nbsp;&nbsp;</span>Draw Structure
          </button>

          <div className="mb-2 mt-8 text-xl font-semibold">Component 2</div>
          <ComponentInput
            className="h-14 w-full text-xl placeholder:text-gray-400"
            value={component2}
            onChange={e => setComponent2(e.target.value)}
          />
          <button
            className="mt-2 text-xl font-semibold hover:text-blue-500"
            type="button"
            onClick={() => {
              setIsComponent1(false);
              setShowKetcher(true);
            }}
          >
            <span className="text-black">-&nbsp;&nbsp;</span>Draw Structure
          </button>
          <button
            type="submit"
            className="mt-12 w-full rounded-xl bg-slate-400 p-2 text-xl font-semibold text-white hover:bg-slate-500"
          >
            Submit
          </button>

          <h5 className="mt-16 text-xl font-semibold">Search Examples</h5>
          <div className="mt-2 flex w-fit flex-wrap border border-gray-500 bg-white">
            <div className="px-4 py-2 text-center text-slate-900">
              <div className="text-lg font-bold">InChI</div>
              <div className="text-lg">InChI=1S/C6H6O2/c7-5-3-1-2-4-6&#40;5&#41;8/h1-4,7-8H</div>
            </div>
            <div className="px-4 py-2 text-center text-slate-900">
              <div className="text-lg font-bold">IUPAC Name</div>
              <div className="text-lg">1,2-benzenediol</div>
            </div>
            <div className="px-4 py-2 text-center text-slate-900">
              <div className="text-lg font-bold">Name</div>
              <div className="text-lg">Catechol</div>
            </div>
            <div className="px-4 py-2 text-center text-slate-900">
              <div className="text-lg font-bold">CASRN</div>
              <div className="text-lg">120-80-9</div>
            </div>
            <div className="px-4 py-2 text-center text-slate-900">
              <div className="text-lg font-bold">SMILES</div>
              <div className="text-lg">Oc1ccccc1O</div>
            </div>
          </div>
        </form>
        <NoWarranty />
      </ContentContainer>

      <KetcherModal open={showKetcher} setOpen={setShowKetcher} handleSubmit={modalClick} ref={ketcherRef} />
    </>
  );
}
