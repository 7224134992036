import Reference from 'components/Reference';

export default function GCGCNDocument() {
  return (
    <div className="max-w-screen-lg text-lg">
      <div>
        Group Contribution based Graph Convolution Network &#40;GC-GCN&#41; is an advanced QSPR model for
        predicting thermophysical properties of chemical compounds. It bridges the gap between simple group
        contribution methods and complex molecular graph neural networks by utilizing graphs of functional
        groups instead of atomic graphs. This approach significantly reduces the number of adjustable
        parameters, enhances model robustness, and improves predictive accuracy.
      </div>
      <div className="mt-4">
        GC-GCN accurately distinguished properties of isomers by utilizing connectivity information of
        functional groups, a challenging task for traditional Group Contriubtion Methods &#40;GCMs&#41;.
        GC-GCN showed superior accuracy compared to traditional GCMs like Marrero & Gani and Rowley & Wilding
        methods, while covering chemical compounds where conventional GCMs are limited by their molecular
        representation.
      </div>
      <div className="mt-4">
        Moreover, GC-GCN showed robust behavior even with limited experimental data and potential outliers. It
        maintained accurate predictions despite the presence of noisy or inconsistent data points.
      </div>
      <img className="my-4 max-w-screen-md" src="/images/document/GC-GCN/graph.png" />
      <div className="mt-4 font-semibold">Reference</div>
      <Reference index="1">
        Hwang, Sun Yoo, and Jeong Won Kang. "Group Contribution-Based Graph Convolution Network: Pure Property
        Estimation Model." International Journal of Thermophysics 43.9 &#40;2022&#41;: 136.
      </Reference>
    </div>
  );
}
