import { forwardRef, Suspense, lazy } from 'react';
import { VscChromeClose } from 'react-icons/vsc';

import Modal from 'components/Modal';
import Loading from 'components/Loading';
const KetcherEditor = lazy(() => import('components/Ketcher'));

const KetcherModal = forwardRef(({ open, setOpen, handleSubmit }, ref) => {
  return (
    <Modal open={open} setOpen={setOpen} className="w-full bg-white p-2 lg:w-[40rem]">
      <div className="border-2 p-2">
        <VscChromeClose className="mb-2 ml-auto cursor-pointer text-xl" onClick={() => setOpen(false)} />
        <div className="min-h-[29rem] border-2">
          <Suspense fallback={<Loading className="h-[29rem] w-full" />}>
            <KetcherEditor ref={ref} className="w-full" />
          </Suspense>
        </div>
        <div className="ml-auto mt-2 w-fit">
          <button onClick={handleSubmit} className="text-lg font-medium text-gray-400 hover:text-black">
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
});

export default KetcherModal;
