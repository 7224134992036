function roundToSignificantFigures(num, significantDigits) {
  if (num) {
    return Number(num).toPrecision(significantDigits);
  } else if (num === 0) return 0;
}

function capitalizeFirstLetter(str) {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function checkIsNull(str) {
  if (str === '20000') return false;
}

export { roundToSignificantFigures, capitalizeFirstLetter };
