import { forwardRef, useRef, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ContentContainer, ContentTitle } from 'components/Content';
import GradeDocument from 'components/document/Grade';
import GCGCNDocument from 'components/document/GCGCN';
import COSMOSACDocument from 'components/document/COSMO-SAC';
import COSMOSACMLDocument from 'components/document/COSMO-SAC-ML';

const SectionTitle = forwardRef(({ children }, ref) => {
  return (
    <div className="mb-2 mt-2 border-b-2 border-slate-500 pb-1 pt-14 text-2xl font-semibold" ref={ref}>
      {children}
    </div>
  );
});

export default function Document() {
  const gradeRef = useRef();
  const GCGCNRef = useRef();
  const COSMOSACRef = useRef();
  const COSMOSACMLRef = useRef();

  const [searchParams] = useSearchParams();
  const scrollTo = searchParams.get('scroll');

  useEffect(() => {
    switch (scrollTo) {
      case 'grade':
        gradeRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'gc-gcn':
        GCGCNRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'cosmo-sac':
        COSMOSACRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'cosmo-sac-ml':
        COSMOSACMLRef.current?.scrollIntoView({ behavior: 'smooth' });
        break;
    }
  }, [scrollTo]);

  return (
    <ContentContainer>
      <ContentTitle>Document</ContentTitle>
      <h4 className="mb-2 text-2xl font-bold">Content</h4>
      <ol className="space-y-1 text-xl">
        <li
          className="click-text block"
          onClick={() => gradeRef.current?.scrollIntoView({ behavior: 'smooth' })}
        >
          <span className="mr-2 text-black">-</span>Grade
        </li>
        <li
          className="click-text block"
          onClick={() => GCGCNRef.current?.scrollIntoView({ behavior: 'smooth' })}
        >
          <span className="mr-2 text-black">-</span>
          GC-GCN
        </li>
        <li
          className="click-text block"
          onClick={() => COSMOSACRef.current?.scrollIntoView({ behavior: 'smooth' })}
        >
          <span className="mr-2 text-black">-</span>COSMO-SAC
        </li>
        <li
          className="click-text block"
          onClick={() => COSMOSACMLRef.current?.scrollIntoView({ behavior: 'smooth' })}
        >
          <span className="mr-2 text-black">-</span>COSMO-SAC-ML
        </li>
      </ol>

      <SectionTitle ref={gradeRef}>Grade</SectionTitle>
      <GradeDocument />

      <SectionTitle ref={GCGCNRef}>
        Group Contribution based Graph Convolution Network &#40;GC-GCN&#41;
      </SectionTitle>
      <GCGCNDocument />

      <SectionTitle ref={COSMOSACRef}>COSMO-SAC</SectionTitle>
      <COSMOSACDocument />

      <SectionTitle ref={COSMOSACMLRef}>COSMO-SAC-ML</SectionTitle>
      <COSMOSACMLDocument />
    </ContentContainer>
  );
}
